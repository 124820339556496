import React, { Component } from "react";
import { translate } from "react-i18next";
import styles from "./index.scss";
import MetaTags from 'react-meta-tags';
import Carousel from "components/carousel";
import { actions as uiActions, ModalTypes } from 'shared/redux/ui';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { selectors as authSelectors } from 'shared/redux/auth';
import LogotypeStrip from 'components/logotypesStrip';
import TabsComponent from './components/tabsComponent';
import AssetHelper from 'AssetHelper';
import CoronavirusInfoModal from 'components/coronavirusInfoModal';
import i18next from "i18next";
import Tabs from '../../components/tabs/tabs';
import YouTube from 'react-youtube';
import Lottie from 'lottie-react-web';
import animation1 from './components/anim_account.json';
import animation2 from './components/anim_upload.json';
import animation3 from './components/anim_edit.json';
import animationSloganPl from './components/anim_polish.json';
import animationSloganEn from './components/anim_english.json';

class HomePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      coronaVirusModalOpen: false,
    };

    this.onRegisterClick =  this.onRegisterClick.bind(this);
    this.toggleCoronavirusModal = this.toggleCoronavirusModal.bind(this);
  }
  componentDidMount() {
    if (this.props.location.search === '?accountDeletedSuccessfully') {
      this.props.notifications.add(this.props.t("home.accountDeleteInfo"));
      this.props.history.push({
        search: '',
      });
    }
    const coronavirusInfoAlreadyDisplayed = localStorage.getItem('coronavirusInfoDisplayed');
    if(coronavirusInfoAlreadyDisplayed){
      return;
    } else {
      setTimeout(this.toggleCoronavirusModal, 3000);
      localStorage.setItem('coronavirusInfoDisplayed', true);
    }
  }
  componentWillUnmount() {
    this.props.navBar.setStyle();
  }
  toggleCoronavirusModal() {
    this.setState(state => ({
      coronaVirusModalOpen: !state.coronaVirusModalOpen,
    }));
  }

  onRegisterClick() {
    this.props.showModal({
      modalType: ModalTypes.Registration,
    });
  }
  render() {
    const { coronaVirusModalOpen } = this.state;
    const { t, user } = this.props,
      isLoggedIn = user.state === 'loggedIn',
      carouselItems = [
        {
          title: t("home.carouselItemsOne"),
          content: t("home.carouselContentOne"),
          image: AssetHelper.staticPath('/static/home/home/testimonials/img-testimonials-1.png'),
          id: 1
        },
        {
          title: t("home.carouselItemsTwo"),
          content: t("home.carouselContentTwo"),
          image: AssetHelper.staticPath('/static/home/home/testimonials/img-testimonials-2.png'),
          id: 2
        },
        {
          title: t("home.carouselItemsThree"),
          content: t("home.carouselContentThree"),
          image: AssetHelper.staticPath('/static/home/home/testimonials/img-testimonials-3.png'),
          id: 3
        }
      ];

    let video;
    if (!video) { video = document.createElement('video'); }

    let shouldPlayWebm = video.canPlayType('video/webm; codecs="vp8, vorbis') === 'probably',
      videoPath = shouldPlayWebm ? '/static/home/home/videos/Evryplace_home_vid.webm' : '/static/home/home/videos/Evryplace_home_vid.mp4',
      tabs = [
        {
          label: t("home.editorHeading"),
          heading: t("home.editor"),
          description: t("home.editorParagraph")
        },
        {
          label: t("home.playerHeading"),
          heading: t("home.player"),
          description: t("home.playerParagraph")
        },
        {
          label: t("home.coviewHeading"),
          heading: t("home.coview"),
          description: t("home.coviewParagraph")
        },
        {
          label: t("home.brandingHeading"),
          heading: t("home.branding"),
          description: t("home.brandingParagraph")
        }
      ]
    let playerWidth = 640;
    if (window.innerWidth < 670 && window.innerWidth > 410) {
      playerWidth = 403;
    } else if (window.innerWidth <= 410) {
      playerWidth = window.innerWidth * 0.95;
    }
    const playerHeight = window.innerWidth < 670 ? 250 : 390;
    return (
      <div className="npt home">
        <MetaTags>
          <title>{t("home.metaTitle")}</title>
          <meta name="description" content={t("home.metaDescription")} />
          <meta name="keywords" content={t("home.metaKeywords")} />
        </MetaTags>
        <section className="hero overlay">
          <img className="hero__mobile" src="/static/home/home/home-video.jpg" />
          <video className="hero__video" loop muted preload="none" autoPlay poster={AssetHelper.staticPath('/static/home/home/home-video.jpg')}>
            <source src={AssetHelper.staticPath(videoPath)} />
          </video>
          <div className="hero__wrapper">
            <div className="hero__hgroup">
              <h1 className="hero__heading" dangerouslySetInnerHTML={{ __html: t("home.headerTitle") }} />
              <h5 className="hero__subheading" dangerouslySetInnerHTML={{ __html: t("home.headerSubtitle") }} />
              {
                isLoggedIn ?
                  <a className="button button--larger cta__button" dangerouslySetInnerHTML={{ __html: t('common.createPresentation') }} href="/panel/#/projects"></a> :
                  <div>
                    <a className="hero__button button button--larger" onClick={this.onRegisterClick}>
                      {t('home.createAccountBtn')}
                    </a>
                    <p className="hero__tagline">{t("home.tagline")}</p>
                  </div>
              }
            </div>
          </div>
        </section>
        <LogotypeStrip />
        <section className="product tabs-container" id="product">
          <div style={{ height: 70 }}></div>
          <h4 className="product__heading" dangerouslySetInnerHTML={{ __html: t('home.productHeading') }} />
          <Tabs tabs={tabs}><TabsComponent /></Tabs>
          <div className="tabsMob">
            {tabs.map((tab, i)=><TabsComponent val={i} label={tab.heading} description={tab.description}/>)}
          </div>
        </section>
        <section className="divider"></section>
        <section className="how-to-start wrapper" id="howToStart">
          <div style={{ height: 110 }}></div>
          <h2 className="how-to-start__heading">{t("home.howToStart")}</h2>
          <div className="how-to-start__box-container">
            <article className="how-to-start__box">
              <div className="how-to-start__image">
                <Lottie
                  options={{
                    animationData: animation1
                  }}
                />
              </div>
              <div className="how-to-start__box-content">
                <p className="how-to-start__box-heading">{t("home.howToStartBox1Text1")}</p>
                <p className="how-to-start__box-description">
                  <a onClick={this.onRegisterClick}>{t("home.howToStartBox1Text2")}</a>{t("home.howToStartBox1Text3")}<a href="/pricing">{t("home.howToStartBox1Text4")}</a>{t("home.howToStartBox1Text5")}<a href={i18next.language.includes('pl') ? '/knowledge-base/evryplace-subscription-plans' : '/pl/knowledge-base/plany-subskrypcji-evryplace'}>{t("home.howToStartBox1Text6")}</a>{t("home.howToStartBox1Text7")}.
                </p>
              </div>
            </article>
            <article className="how-to-start__box">
              <div className="how-to-start__image">
                <Lottie
                  options={{
                    animationData: animation2
                  }}
                />
              </div>
              <div className="how-to-start__box-content">
                <p className="how-to-start__box-heading">{t("home.howToStartBox2Text1")}</p>
                <p className="how-to-start__box-description">{t("home.howToStartBox2Text2")}</p>
              </div>
            </article>
            <article className="how-to-start__box">
              <div className="how-to-start__image">
                <Lottie
                  options={{
                    animationData: animation3
                  }}
                />
              </div>
              <div className="how-to-start__box-content">
                <p className="how-to-start__box-heading">{t("home.howToStartBox3Text1")}</p>
                <p className="how-to-start__box-description">{t("home.howToStartBox3Text2")}</p>
              </div>
            </article>
          </div>
          <p className="how-to-start__subheading">{t("home.howToStartInfoText1")}<a href="/knowledge-base">{t("home.howToStartInfoText2")}</a>{t("home.howToStartInfoText3")}</p>
          <YouTube
            videoId="kjLAPqrQc8s"
            opts={{
              height: playerHeight,
              width: playerWidth,
            }}
          />
        </section>
        <section className="benefits wrapper" id="benefits">
          <div style={{ height: 20 }}></div>
          <h2 className="benefits__heading">{t("home.benefits")}</h2>
          <p className="benefits__subheading">{t("home.benefitsSubhead")}</p>
          <div className="benefits__box-container">
            <article className="benefits__box">
              <img className="benefits__img" src={AssetHelper.staticPath('/static/home/home/img-benefits-box-1.jpg')} alt="Benefits graphic" />
              <div className="benefits__box-content">
                <p className="benefits__box-heading strip">{t("home.benefitsBox1heading")}</p>
                <p className="benefits__box-description">{t("home.benefitsBox1description")}</p>
              </div>
            </article>
            <article className="benefits__box benefits__box--second">
              <img className="benefits__img" src={AssetHelper.staticPath('/static/home/home/img-benefits-box-2.jpg')} alt="Benefits graphic" />
              <div className="benefits__box-content">
                <p className="benefits__box-heading strip">{t("home.benefitsBox2heading")}</p>
                <p className="benefits__box-description">{t("home.benefitsBox2description")}</p>
              </div>
            </article>
            <article className="benefits__box benefits__box--third">
              <img className="benefits__img" src={AssetHelper.staticPath('/static/home/home/img-benefits-box-3.jpg')} alt="Benefits graphic" />
              <div className="benefits__box-content">
                <p className="benefits__box-heading strip" dangerouslySetInnerHTML={{ __html: t("home.benefitsBox3heading") }}></p>
                <p className="benefits__box-description">{t("home.benefitsBox3description")}</p>
              </div>
            </article>
          </div>
        </section>
        <section className="cta wrapper">
          <h4 className="cta__heading" dangerouslySetInnerHTML={{ __html: t('home.ctaHeading') }}></h4>
          {
            isLoggedIn ?
              <a className="button button--larger cta__button" dangerouslySetInnerHTML={{ __html: t('common.createPresentation') }} href="/panel/#/projects"></a> :
              <a className="button button--larger cta__button" onClick={this.onRegisterClick}>
                {t('home.createAccountBtn')}
              </a>
          }
        </section>
        <section className="features wrapper" id="functions">
          <div style={{ height: 35 }}></div>
          <h3 className="features__heading">{t('home.functions')}</h3>
          <div className="features__container">
            <img className="features__img" src={AssetHelper.staticPath('/static/home/home/functions-1.jpg')} />
            <ul className="features__list">
              <li className="features__item">
                <p className="list-item-heading features__item-heading features__item-heading--first">{t('home.featuresItem1Heading')}</p>
                <p className="list-item-desc">{t('home.featuresItem1Content')}</p>
              </li>
              <li className="features__item">
                <p className="list-item-heading features__item-heading features__item-heading--second">{t('home.featuresItem2Heading')}</p>
                <p className="list-item-desc">{t('home.featuresItem2Content')}</p>
              </li>
              <li className="features__item">
                <p className="list-item-heading features__item-heading features__item-heading--third">{t('home.featuresItem3Heading')}</p>
                <p className="list-item-desc">{t('home.featuresItem3Content')}</p>
              </li>
            </ul>
          </div>
          <div className="features__container features__container--reversed">
            <img className="features__img" src={AssetHelper.staticPath('/static/home/home/functions-2.jpg')} />
            <ul className="features__list">
              <li className="features__item">
                <p className="list-item-heading features__item-heading features__item-heading--fourth">{t('home.featuresItem4Heading')}</p>
                <p className="list-item-desc">{t('home.featuresItem4Content')}</p>
              </li>
              <li className="features__item">
                <p className="list-item-heading features__item-heading features__item-heading--fifth">{t('home.featuresItem5Heading')}</p>
                <p className="list-item-desc">{t('home.featuresItem5Content')}</p>
              </li>
              <li className="features__item">
                <p className="list-item-heading features__item-heading features__item-heading--sixth">{t('home.featuresItem6Heading')}</p>
                <p className="list-item-descs">{t('home.featuresItem6Content')}</p>
              </li>
            </ul>
          </div>
        </section>
        <section className="how-can-help wrapper">
          <p className="how-can-help__heading">{t('home.howCanHelpHeading')}</p>
          <div className="how-can-help__boxes">
            <div className="how-can-help__box">
              <img className="how-can-help__box-img" src={AssetHelper.staticPath('/static/home/home/how-can-help/img-how-can-help-1.jpg')} alt="Workflow img" />
              <p className="how-can-help__box-title">{t('home.howCanHelpBox1Title')}</p>
              <ul className="how-can-help__list">
                <li className="how-can-help__list-item bullet bullet--green">{t('home.howCanHelpBox1ListItem1')}</li>
                <li className="how-can-help__list-item bullet bullet--green">{t('home.howCanHelpBox1ListItem2')}</li>
                <li className="how-can-help__list-item bullet bullet--green">{t('home.howCanHelpBox1ListItem3')}</li>
                <li className="how-can-help__list-item bullet bullet--green">{t('home.howCanHelpBox1ListItem4')}</li>
              </ul>
            </div>
            <div className="how-can-help__box how-can-help__box--second">
              <img className="how-can-help__box-img" src={AssetHelper.staticPath('/static/home/home/how-can-help/img-how-can-help-2.jpg')} alt="Workflow img" />
              <p className="how-can-help__box-title">{t('home.howCanHelpBox2Title')}</p>
              <ul className="how-can-help__list">
                <li className="how-can-help__list-item bullet bullet--sea">{t('home.howCanHelpBox2ListItem1')}</li>
                <li className="how-can-help__list-item bullet bullet--sea">{t('home.howCanHelpBox2ListItem2')}</li>
                <li className="how-can-help__list-item bullet bullet--sea">{t('home.howCanHelpBox2ListItem3')}</li>
                <li className="how-can-help__list-item bullet bullet--sea">{t('home.howCanHelpBox2ListItem4')}</li>
              </ul>
            </div>
            <div className="how-can-help__box how-can-help__box--third">
              <img className="how-can-help__box-img" src={AssetHelper.staticPath('/static/home/home/how-can-help/img-how-can-help-3.jpg')} alt="Workflow img" />
              <p className="how-can-help__box-title">{t('home.howCanHelpBox3Title')}</p>
              <ul className="how-can-help__list">
                <li className="how-can-help__list-item bullet bullet--yellow">{t('home.howCanHelpBox3ListItem1')}</li>
                <li className="how-can-help__list-item bullet bullet--yellow">{t('home.howCanHelpBox3ListItem2')}</li>
                <li className="how-can-help__list-item bullet bullet--yellow">{t('home.howCanHelpBox3ListItem3')}</li>
              </ul>
            </div>
          </div>
          <div className="how-can-help__button-wrap">
            <p className="how-can-help__30days">{t('home.howCanHelp30days')}</p>
            {
              isLoggedIn ?
                <a className="button button--larger cta__button" dangerouslySetInnerHTML={{ __html: t('common.createPresentation') }} href="/panel/#/projects"></a> :
                <a className="button button--larger how-can-help__button" onClick={this.onRegisterClick}>
                  {t('home.createAccountBtn')}
                </a>
            }
          </div>
        </section>
        <section className="testimonials wrapper">
          <h4 className="testimonials__head">{t('home.testimoialsHead')}</h4>
          <p className="testimonials__subhead">{t('home.testimonialsSubHead')}</p>
          <Carousel items={carouselItems} />
          <div className="testimonials__dot testimonials__dot--1"></div>
          <div className="testimonials__dot testimonials__dot--2"></div>
          <div className="testimonials__dot testimonials__dot--3"></div>
          <div className="testimonials__dot testimonials__dot--5"></div>
          <div className="testimonials__dot testimonials__dot--6"></div>
          <div className="testimonials__dot testimonials__dot--7"></div>
        </section>
        <section className="media">
          <img className="media__img" src={AssetHelper.staticPath('/static/home/home/your-presentations.jpg')} alt="Multiplatform picture" />
          <div className="media__hgroup">
            <p className="media__head">{t('home.mediaHeader')}</p>
            <p className="media__subheader1">{t('home.mediaSubhead1')}</p>
            <p className="media__subheader2">{t('home.mediaSubhead2')}</p>
            <div className="media__links">
              <a className="media__link" href="https://play.google.com/store/apps/details?id=pl.fream.evryplace.flat.evr" target="_blank">
                <img className="media__link-img media__link-img--google" src={AssetHelper.staticPath('/static/home/media/google.svg')} alt="" />
              </a>
              <a className="media__link" href="https://itunes.apple.com/pl/app/evryplace/id1276176541?l=pl&mt=8" target="_blank">
                <img className="media__link-img media__link-img--appstore" src={AssetHelper.staticPath('/static/home/media/appstore.svg')} alt="" />
              </a>
              <a className="media__link" href="https://www.oculus.com/experiences/gear-vr/1739742929422865/" target="_blank">
                <img className="media__link-img media__link-img--oculus" src={AssetHelper.staticPath('/static/home/media/oculus.svg')} alt="" />
              </a>
            </div>
          </div>
        </section>
        <section className="slogan">
          <div className="animation">
            <Lottie
              options={{
                animationData: i18next.language === 'pl' ? animationSloganPl : animationSloganEn
              }}
            />
          </div>
          {
            isLoggedIn ?
              <a className="button button--larger cta__button" dangerouslySetInnerHTML={{ __html: t('pricing.changePlan') }} href="/panel/#/payment"></a> :
              <div>
                <a className="button button--larger button--cta slogan__button" onClick={this.onRegisterClick}>
                  {t('home.createAccountBtn')}
                </a><p className="slogan__tagline">{t("home.tagline")}</p>
              </div>
          }
        </section>
        <CoronavirusInfoModal open={coronaVirusModalOpen} onClose={this.toggleCoronavirusModal}/>
      </div>
    );
  }
}

const {
  showModal,
} = uiActions;

const {
  getCurrentUser
} = authSelectors;

const mapStateToProps = state => ({
  user: getCurrentUser(state)
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    showModal,
  }, dispatch),
});

export default translate('translations')(connect(mapStateToProps, mapDispatchToProps,)(HomePage));
