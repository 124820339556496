import React, { Component } from 'react';
import Explore from './explore/index.js';
import MadeByMe from './madeByMe/index.jsx';
import Redirect3ds from './3dsredirect/index';
import NotFound from './not-found/index.jsx';
import Presentation from './presentation/index.js';
import Faq from './faq/index';
import Terms from './terms/index.jsx';
import Privacy from './privacy/index.jsx';
import Pricing from './pricing/index.jsx';
import HomePage from './home/index.jsx';
import Embed from './embed/index.js';
import ViewingHost from './viewing-host/index.jsx';
import loadNowystyl from 'bundle-loader?lazy!./nowystyl/index.jsx';
import loadNowystylNotFound from 'bundle-loader?lazy!./nowystyl-not-found/index.jsx';
import Login from './login/index.jsx';
import Bundle from 'components/bundle/index.jsx';
import Viewing from './viewing/index.jsx';
import loadLgNotFound from 'bundle-loader?lazy!./lagardere/not-found/index.jsx';
import loadLgMain from 'bundle-loader?lazy!./lagardere/main/index.jsx';
import loadLgExplore from 'bundle-loader?lazy!./lagardere/explore/index.jsx';
import loadLgPresentation from 'bundle-loader?lazy!./lagardere/presentation/index.js';
import { NavBarContext } from 'components/navBar';
import RealEstate from './real-estate/index';
import Trainings from './trainings/index';
import Confirmation from './confirmation/index';
import Tourism from './tourism/index';
import { DependencyContext, WithDependency } from 'shared/DependencyContext';
import redirect3ds from "./3dsredirect";


const BundleComponent = (Component) => {
  return (props) => {
    return <Bundle load={Component}>
      {(LoadedComponent) => <LoadedComponent {...props} />}
    </Bundle>;
  };
};

const withNavBar = (Component) => {
  return (props) => {
    return <NavBarContext.Consumer>
      {navBar => (
        navBar ? <Component
          {...props}
          navBar={navBar}
        /> : <div></div>
      )}
    </NavBarContext.Consumer>;
  };
};

const withDependency = (Component) => {
  let firstLoad = true;

  return (props) => {
    return <DependencyContext.Consumer>
      {({navBar, player, notifications}) => {
        if (!firstLoad && (!player || !navBar || !notifications)) {
          console.log('player, navBar or notifications undefined');
        }

        firstLoad = false;
        return (navBar && player && notifications) ? <Component
          {...props}
          player={player}
          navBar={navBar}
          notifications={notifications}
        /> : <div></div>;
      }}
    </DependencyContext.Consumer>;
  };
};

export default {
  Explore: Explore,
  MadeByMe: MadeByMe,
  Redirect3ds: Redirect3ds,
  Confirmation: withDependency(Confirmation),
  Presentation: Presentation,
  Viewing: Viewing,
  NotFound: withDependency(NotFound),
  Faq: withNavBar(Faq),
  Pricing: Pricing,
  Terms: withDependency(Terms),
  Privacy: withDependency(Privacy),
  HomePage: withDependency(HomePage),
  Embed: withNavBar(Embed),
  Nowystyl: BundleComponent(loadNowystyl),
  NowystylNotFound: BundleComponent(loadNowystylNotFound),
  ViewingHost: ViewingHost,
  Login: Login,
  LgMain: BundleComponent(loadLgMain),
  LgNotFound: BundleComponent(loadLgNotFound),
  LgExplore: withNavBar(BundleComponent(loadLgExplore)),
  RealEstate: withDependency(RealEstate),
  Trainings: withDependency(Trainings),
  Tourism: Tourism,
  LgPresentation: withNavBar(BundleComponent(loadLgPresentation)),
};
