import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { translate } from "react-i18next";
import { bindActionCreators } from 'redux';
import {actions, selectors} from "shared/redux/auth";
import { actions as uiActions } from 'shared/redux/ui';
import { connect } from 'react-redux';
import './index.scss';
import LoginModal from "components/loginModal/index.jsx";
import {ModalTypes} from "../../shared/redux/ui";

class Login extends Component {
  constructor(props) {
    super(props);

    this.openResetPasswordModal = this.openResetPasswordModal.bind(this);
  }
  openResetPasswordModal() {
    this.props.showModal({
      modalType: ModalTypes.ResetPassword,
    });
  }
  render() {
    let panelRedirectPath;
    if (window.location.search.includes('panelRedirectPath')) {
      panelRedirectPath = decodeURIComponent(window.location.search.replace('?panelRedirectPath=', ''));
    }

    const shouldRedirect =
      (panelRedirectPath || (this.props.location.state && this.props.location.state.from))
      && this.props.currentUser.state === 'loggedIn';

    if (shouldRedirect) {
      if (panelRedirectPath) {
        window.open(panelRedirectPath, '_self');
      } else {
        return <Redirect to={{
          pathname: this.props.location.state.from.pathname
        }}/>
      }
    } else {
      return <LoginModal
        open={true}
        forceLogin={true}
        user={this.props.currentUser}
        onSubmit={this.props.login}
        openResetPasswordModal={this.openResetPasswordModal}
      />
    }
  }
}

function LoginNavBar(props) {
  return <NavBarContext.Consumer>
    {navBar => (
      <Login
        {...props}
        navBar={navBar}
      />
    )}
  </NavBarContext.Consumer>;
}

const {
  getCurrentUser
} = selectors;

const {
  fetchCurrentUser,
  login,
} = actions;

const {
  showModal,
} = uiActions;

const mapStateToProps = state => ({
  currentUser: getCurrentUser(state),
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    fetchCurrentUser,
    login,
    showModal,
  }, dispatch),
});

export default translate("translations")(connect(
  mapStateToProps,
  mapDispatchToProps,
)(Login));
